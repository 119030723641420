/*eslint-disable*/
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Notification from "../../@core/components/notifications"
import { useTranslation } from "react-i18next"
import { auth } from "../../api/auth"
import { USER_TYPES } from "../../resources/enums"
import { validateEmail } from "../../api/parameters"
import { getByItaCode } from "../../api/ita"
import { userContext } from "../../context/user"

const useLogin = () => {
	const { t } = useTranslation()
	const { customNotification } = Notification()
	const { setUser, setToken } = userContext()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [openModalItaCode, setOpenModalItaCode] = useState(false)
	const [userInfoTemp, setUserInfoTemp] = useState(false)
	const [userEmployeeTemp, setUserEmployeeTemp] = useState(false)
	const [itaCode, setItaCode] = useState("")

	const [formData, setFormData] = useState()

	const toggleModal = () => {
		setOpenModalItaCode(!openModalItaCode)
	}

	const signIn = async (values) => {
		setIsLoading(true)

		const response = await auth({
			...formData,
			email: values.email,
			password: values.password,
			iboCode: 0
		})
		if (response?.status === 200) {
			const { user, token, ibo } = response?.data?.data;
			localStorage.setItem("tokenBP", token)
			if(user?.userTypeId === USER_TYPES.EMPLOYEE) {
				const validEmail = await validateEmail('LoginBlockpay')
				if(validEmail?.[0].value) {
					const emails = validEmail?.[0].value?.split(',')
					const isAvailable = emails?.some(item => item === values.email)
					if(isAvailable) {
						localStorage.setItem("userEmployee", JSON.stringify(user))
						setUserInfoTemp(user)
						setUserEmployeeTemp({...user, token})
						setOpenModalItaCode(true)
					} else {
						if (user.enabledOtp) {
							localStorage.setItem("tokenBP", token)
							localStorage.setItem("userInfoTFA", JSON.stringify({...user, ...ibo}))
							navigate('/login-validate')
						} else {
							localStorage.setItem("tokenBP", token)
							localStorage.setItem("userInfo", JSON.stringify({...user, ...ibo}))
							setUser({...user, ...ibo})
							navigate('/profile')
						}
					}
				}
			} else {
				if (user.enabledOtp) {
					localStorage.setItem("tokenBP", token)
					localStorage.setItem("userInfoTFA", JSON.stringify({...user, ...ibo}))
					navigate('/login-validate')
				} else {
					localStorage.setItem("userInfo", JSON.stringify({...user, ...ibo}))
					localStorage.setItem("tokenBP", token)
					setUser({...user, ...ibo})
					navigate('/profile')
				}
			}
		} else {
			customNotification(t("Error"), t("LoginError"), "danger")
		}
		setIsLoading(false)
	}

	const logout = () => {
		localStorage.removeItem("userInfo")
		localStorage.removeItem("userInfoTFA")
		localStorage.removeItem("tokenVT")
		localStorage.removeItem("userEmployee")
		localStorage.removeItem("tokenBP")
		navigate('/login')
		// window.history.go(0)
	}

	const handleChange = (e) => {
		const { name, value } = e.target

		setFormData({
			...formData,
			[name]: value
		})
	}

	const signInWithItaCode = async (values) => {
		const user = await getByItaCode(values.itaCode)
		if (user && user?.id) {
			if (userEmployeeTemp.enabledOtp) {
				
				localStorage.setItem("userInfoTFA", JSON.stringify({
					userId: user.userId,
					affiliationDate: user.creationDate,
					userTypeId: 1,
					name: user.name,
					lastName: user.lastName,
					email: user.email,
					imageUrl: user.imageUrl,
					code: user.code,
					otpEnabled: true,
					renewalDate: user.renewalDate
				  }))
				navigate('/login-validate')
			} else {
				localStorage.setItem("userInfo", JSON.stringify({
					userId: user.userId,
					affiliationDate: user.creationDate,
					userTypeId: 1,
					name: user.name,
					lastName: user.lastName,
					email: user.email,
					imageUrl: user.imageUrl,
					code: user.code,
					otpEnabled: true,
					renewalDate: user.renewalDate,
				  }))
				navigate('/')
			}
			
		} else {
			customNotification(t("Error"), t("Código de ibo no encontrado"), "danger")
		}

	}

	const signInAsEmployee = () => {
		if (userInfoTemp.enabledOtp) {
			localStorage.setItem("userInfoTFA", JSON.stringify(userInfoTemp))
			navigate('/login-validate')
		} else {
			localStorage.setItem("userInfo", JSON.stringify(userInfoTemp))
			setUser(userInfoTemp)
			setToken(userInfoTemp.token)
			navigate('/profile')
		}
	}

	return {
		signIn,
		handleChange,
		logout,
		isLoading,
		openModalItaCode,
		setItaCode,
		signInWithItaCode,
		toggleModal,
		signInAsEmployee
	}
}

export default useLogin
